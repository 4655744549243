import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Project Anarkoin',
  description:
    'The most popular AMM on BSC by user count! Earn ANRK through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by Project Anarkoin), NFTs, and more, on a platform you can trust.',
  image: 'https://anarkoin.io/logo.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('Project Anarkoin')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('Project Anarkoin')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('Project Anarkoin')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('Project Anarkoin')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('Project Anarkoin')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('Project Anarkoin')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('Project Anarkoin')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('Project Anarkoin')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('Project Anarkoin')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('Project Anarkoin')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('Project Anarkoin')}`,
      }
    default:
      return null
  }
}
